<template>
  <div :class="$style.auth">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Auth2Layout'
}
</script>

<style module lang="scss">
.auth {
  display: flex;
  align-items: center;
  background: var(--white);
  min-height: 100%;
}
</style>
